import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import * as applicationActions from '~actions/application-actions';
import * as uiActions from '~actions/ui-actions';

import K8sApplicationDetailsTable from '~components/applications/k8s-application-details-table';
import LegacyApplicationDetailsTable from '~components/applications/legacy-application-details-table';
import * as dialogTypes from '~components/dialogs/dialog-types';
import ActionButton from '~components/general/action-button';
import GettingMore from '~components/general/getting-more';
import IString from '~components/general/i-string';

import * as applicationScheduler from '~constants/application-scheduler';
import * as applicationStatus from '~constants/application-status';

import { translate } from '~i18n/localize';

import * as applicationSelectors from '~selectors/application-selectors';
import * as taskSelectors from '~selectors/task-selectors';

export function ApplicationDetails({ application, dispatch }) {

  const handleExport = () =>
    dispatch(uiActions.openDialog({
      type: dialogTypes.EXPORT_APPLICATION_DIALOG,
      data: {
        application
      }
    }));

  const handleClickSuspend = () => {
    dispatch(uiActions.openDialog({
      type: dialogTypes.APPLICATION_TASK_DIALOG,
      data: {
        application,
        purpose: translate('application.task.dialog.suspend.purpose'),
        details: translate('application.task.dialog.suspend.details'),
        onSubmit: () => {
          dispatch(applicationActions.suspendApplication(application.id));
        },
        onFinish: (task) => {
          if (!taskSelectors.isFailed(task)) {
            dispatch(uiActions.closeDialog());
          }
        }
      }
    }));
  };

  const handleClickResume = () => {
    dispatch(uiActions.openDialog({
      type: dialogTypes.APPLICATION_TASK_DIALOG,
      data: {
        purpose: translate('application.task.dialog.resume.purpose'),
        details: translate('application.task.dialog.resume.details'),
        application,
        onSubmit: () => {
          dispatch(applicationActions.resumeApplication(application.id));
        },
        onFinish: (task) => {
          if (!taskSelectors.isFailed(task)) {
            dispatch(uiActions.closeDialog());
          }
        }
      }
    }));
  };

  const handleClickRestart = () => {
    dispatch(uiActions.openDialog({
      type: dialogTypes.APPLICATION_TASK_DIALOG,
      data: {
        purpose: translate('application.task.dialog.restart.purpose'),
        details: translate('application.task.dialog.restart.details'),
        application,
        onSubmit: () => {
          dispatch(applicationActions.restartApplication(application.id));
        },
        onFinish: (task) => {
          if (!taskSelectors.isFailed(task)) {
            dispatch(uiActions.closeDialog());
          }
        }
      }
    }));
  };

  const handleClickPurge = () => {
    dispatch(uiActions.openDialog({
      type: dialogTypes.APPLICATION_TASK_DIALOG,
      data: {
        purpose: translate('application.task.dialog.purge.purpose'),
        details: <IString stringKey="application.task.dialog.purge.details" />,
        application,
        onSubmit: () => {
          dispatch(applicationActions.purgeApplication(application.id));
        },
        onFinish: (task) => {
          if (!taskSelectors.isFailed(task)) {
            dispatch(uiActions.closeDialog());
          }
        }
      }
    }));
  };

  return (
    application
      ? <div className="splitColumns withMobileMargins">
        <div className="column100">

          <div className="sectionTitle flex">
            <IString stringKey="application.details.title" />
            <div className="actionBar inline showTitles">
              { application.exportable && applicationScheduler.isK8s(application) &&
                <ActionButton
                  className="download"
                  onClick={handleExport}
                  title={<IString stringKey="application.details.actions.export" />}
                />
              }
              { applicationStatus.suspendible(application) &&
                <ActionButton
                  className="reset"
                  onClick={handleClickSuspend}
                  title={<IString stringKey="application.details.actions.suspend" />}
                />
              }
              { applicationStatus.resumable(application) &&
                <ActionButton
                  className="reset"
                  onClick={handleClickResume}
                  title={<IString stringKey="application.details.actions.resume" />}
                />
              }
              { applicationStatus.restartable(application) &&
                <ActionButton
                  className="restart"
                  onClick={handleClickRestart}
                  title={<IString stringKey="application.details.actions.restart" />}
                />
              }
              { applicationStatus.purgeable(application) &&
                <ActionButton
                  className="delete"
                  onClick={handleClickPurge}
                  title={<IString stringKey="application.details.actions.purge" />}
                />
              }
            </div>
          </div>

          { applicationScheduler.isK8s(application)
            ? <K8sApplicationDetailsTable application={application} />
            : <LegacyApplicationDetailsTable application={application} /> }
        </div>
      </div>
      : <GettingMore label={translate('general.loading')} />
  );
}

ApplicationDetails.propTypes = {
  application: PropTypes.shape({
    exportable: PropTypes.bool,
    id: PropTypes.number.isRequired
  }),
  dispatch: PropTypes.func
};

const mapStateToProps = (state) => ({
  application: applicationSelectors.getCurrentApplication(state)
});

export default connect(mapStateToProps)(ApplicationDetails);
