import * as types from '~actions/action-types';

export const setApplicationSearch = (query) => ({
  type: types.SET_APPLICATION_SEARCH, query
});

export const clearApplicationSearch = () => ({
  type: types.CLEAR_APPLICATION_SEARCH
});

export const fetchApplicationSearchSuccess = (applications) => ({
  type: types.FETCH_APPLICATION_SEARCH_SUCCESS,
  applications
});

export const fetchApplicationSearchFailure = (applications) => ({
  type: types.FETCH_APPLICATION_SEARCH_FAILURE,
  applications
});

export const setCurrentApplication = (applicationId) => ({
  type: types.SET_CURRENT_APPLICATION,
  applicationId
});

export const clearCurrentApplication = () => ({
  type: types.CLEAR_CURRENT_APPLICATION
});

export const fetchApplication = (applicationId) => ({
  type: types.FETCH_APPLICATION,
  applicationId
});

export const fetchApplicationSuccess = (application) => ({
  type: types.FETCH_APPLICATION_SUCCESS,
  application
});

export const fetchApplicationAuthorizations = (applicationId) => ({
  type: types.FETCH_APPLICATION_AUTHORIZATIONS,
  applicationId
});

export const fetchApplicationAuthorizationsSuccess = (applicationId, authorizations, total) => ({
  type: types.FETCH_APPLICATION_AUTHORIZATIONS_SUCCESS,
  applicationId,
  authorizations,
  total
});

export const fetchApplicationLogs = (applicationId) => ({
  type: types.FETCH_APPLICATION_LOGS,
  applicationId
});

export const fetchApplicationLogsSuccess = (applicationId, applicationLogs) => ({
  type: types.FETCH_APPLICATION_LOGS_SUCCESS,
  applicationId,
  applicationLogs
});

export const resumeApplication = (applicationId) => ({
  type: types.RESUME_APPLICATION,
  applicationId
});

export const resumeApplicationSuccess = (application) => ({
  type: types.RESUME_APPLICATION_SUCCESS,
  application
});

export const suspendApplication = (applicationId) => ({
  type: types.SUSPEND_APPLICATION,
  applicationId
});

export const suspendApplicationSuccess = (application) => ({
  type: types.SUSPEND_APPLICATION_SUCCESS,
  application
});

export const restartApplication = (applicationId) => ({
  type: types.RESTART_APPLICATION,
  applicationId
});

export const restartApplicationSuccess = (application) => ({
  type: types.RESTART_APPLICATION_SUCCESS,
  application
});

export const purgeApplication = (applicationId) => ({
  type: types.PURGE_APPLICATION,
  applicationId
});

export const purgeApplicationSuccess = (applicationId) => ({
  type: types.PURGE_APPLICATION_SUCCESS,
  applicationId
});

export const purgeApplicationFailure = (applicationId) => ({
  type: types.PURGE_APPLICATION_FAILURE,
  applicationId
});
