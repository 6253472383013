import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as uiActions from '~actions/ui-actions';

import * as dialogTypes from '~components/dialogs/dialog-types';
import AccountLink from '~components/general/account-link';
import ActionButton from '~components/general/action-button';
import ApplicationLink from '~components/general/application-link';
import ContentLink from '~components/general/content-link';
import IString from '~components/general/i-string';
import SpaceLink from '~components/general/space-link';
import Time from '~components/general/time';
import UserLink from '~components/general/user-link';
import * as statusTypes from '~components/status/status-types';

import * as contentState from '~constants/content-state';
import * as contentType from '~constants/content-type';

import { translate } from '~i18n/localize';

import * as projectOperations from '~operations/project-operations';

import * as configSelectors from '~selectors/config-selectors';
import * as contentSelectors from '~selectors/content-selectors';

import * as connectApps from '~util/connect-apps';

/**
 * Renders the detailed information about content
 *
 * @param   {object}      content The content to display details about
 * @returns {JSX.Element}         Details of a content item
 */
export default function ContentDetails({ content }) {
  const dispatch = useDispatch();

  const datadogURL = useSelector(configSelectors.getDatadogURL);
  const environment = useSelector(configSelectors.getEnvironment);

  // content update methods aren't supported at `/content/<id>`, these need to be handled on a per-content-type basis.
  // only support deletion for projects for now.
  const deleteable = contentSelectors.deleteable(content) && content.state !== contentState.DELETED && contentType.isProject(content);

  const handleTasks = () => {
    window.open(connectApps.makeTaskinatorURL({ environment, content_id: content.id }), '_blank');
  };

  const handleRUM = () => {
    const u = `${datadogURL}/rum/explorer?agg_q=%40type&cols=%40action.name%2C%40context.result%2C%40context.attributes.source.status%2C%40context.attributes.project_id%2C%40context.attributes.total_duration_ms&live=1d&query=%40action.name%3A%28CREATE_PROJECT+OR+OPEN_PROJECT%29+-%40context.result%3Aidle++%40context.attributes.project_id%3A${content.id}&tab=action`;
    window.open(u, '_blank');
  };

  const handleDelete = () => {
    dispatch(uiActions.openDialog({
      type: dialogTypes.CONFIRM_DIALOG,
      data: {
        purpose: <IString stringKey="content.details.dialogs.delete.purpose" placeholders={{ id: content.id, name: content.name }} />,
        details: <>
          <p><IString stringKey="content.details.dialogs.delete.prompt" placeholders={{ name: content.name }} /></p>
          <p><strong><IString stringKey="content.details.dialogs.delete.warning" /></strong></p>
        </>,
        confirm: async () => {
          try {
            await dispatch(projectOperations.deleteProject(content.id));
            dispatch(uiActions.closeDialog());
            dispatch(uiActions.setStatusMessage({ type: statusTypes.INFO_MESSAGE, message: `Success! ${content.name} #${content.id} has been deleted.` }));
          } catch (error) {
            dispatch(uiActions.setErrorMessage(error));
          }
        }
      }
    }));
  };

  return (
    <div className="splitColumns withMobileMargins">
      <div className="column100">

        <div className="sectionTitle flex">
          <IString stringKey={`content.details.title.${content.content_type}`} />

          <div className="actionBar inline showTitles">
            { deleteable && <ActionButton className="delete" title={translate('general.delete')} onClick={handleDelete} /> }
            <ActionButton className="viewLog" title={translate('general.tasks')} onClick={handleTasks} />
            <ActionButton className="support" title={translate('general.rum')} onClick={handleRUM} />
          </div>

        </div>

        <table className="propertiesTable">
          <tbody>
            <tr>
              <th className="label"><IString stringKey="content.details.table.id" /></th>
              <td className="value">{content.id}</td>
            </tr>
            <tr>
              <th className="label"><IString stringKey="general.name" /></th>
              <td className="value">{content.name}</td>
            </tr>
            <tr>
              <th className="label"><IString stringKey="general.type" /></th>
              <td className="value">
                { contentType.isProject(content) && content.project_type }
                { contentType.isOutput(content) && content.output_type }
              </td>
            </tr>
            <tr>
              <th className="label"><IString stringKey="content.details.table.source_id" /></th>
              <td className="value"><ApplicationLink appId={content.source_id} /></td>
              <td className="value"><em>{content.source?.status}</em></td>
            </tr>
            <tr>
              <th className="label"><IString stringKey="general.account" /></th>
              <td className="value"><AccountLink accountId={content.account_id} /></td>
            </tr>
            <tr>
              <th className="label"><IString stringKey="general.author" /></th>
              <td className="value"><UserLink user={content.author} /></td>
            </tr>
            <tr>
              <th className="label"><IString stringKey="general.space" /></th>
              <td className="value">
                { content.space_id ? <SpaceLink spaceId={content.space_id} /> : <em><IString stringKey="general.none" /></em> }
              </td>
            </tr>
            { contentType.isProject(content) &&
              <>
                <tr>
                  <th className="label"><IString stringKey="content.details.table.parent_id" /></th>
                  <td className="value">
                    { content.parent_id ? <ContentLink contentId={content.parent_id} /> : <em><IString stringKey="general.none" /></em> }
                  </td>
                </tr>
                <tr>
                  <th className="label"><IString stringKey="general.mode" /></th>
                  <td className="value">{content.project_mode}</td>
                </tr>
              </>
            }
            { contentType.isOutput(content) &&
              <tr>
                <th className="label"><IString stringKey="content.details.table.project_id" /></th>
                <td className="value">
                  { content.project_id ? <ContentLink contentId={content.project_id} /> : <em><IString stringKey="general.none" /></em> }
                </td>
              </tr>
            }
            <tr>
              <th className="label"><IString stringKey="general.state" /></th>
              <td className="value">{content.state}</td>
            </tr>
            <tr>
              <th className="label"><IString stringKey="general.visibility" /></th>
              <td className="value">{content.visibility}</td>
            </tr>
            <tr>
              <th className="label"><IString stringKey="general.created_time" /></th>
              <td className="value"><Time value={content.created_time} /></td>
            </tr>
            <tr>
              <th className="label"><IString stringKey="general.updated_time" /></th>
              <td className="value"><Time value={content.updated_time} /></td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  );
}

ContentDetails.propTypes = {
  content: PropTypes.shape({
    account_id: PropTypes.number,
    author: PropTypes.object,
    author_id: PropTypes.number,
    content_type: PropTypes.string,
    created_time: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    output_type: PropTypes.string,   // outputs only
    parent_id: PropTypes.number,     // projects only
    project_id: PropTypes.number,    // outputs only
    project_mode: PropTypes.string,  // projects only
    project_type: PropTypes.string,  // projects only
    source: PropTypes.shape({
      status: PropTypes.string
    }),
    source_id: PropTypes.number,
    space_id: PropTypes.number,
    state: PropTypes.string,
    updated_time: PropTypes.string,
    visibility: PropTypes.string
  }).isRequired
};
