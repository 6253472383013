// auth actions
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const CLEAR_AUTH_USER = 'CLEAR_AUTH_USER';
export const MAINTAIN_ACCESS_TOKEN = 'MAINTAIN_ACCESS_TOKEN';
export const SET_AUTH_USER = 'SET_AUTH_USER';

// ui actions
export const INITIALIZE = 'INITIALIZE';
export const SET_UI_CONFIG = 'SET_UI_CONFIG';
export const SET_UI_READY = 'SET_UI_READY';
export const OPEN_DIALOG = 'OPEN_DIALOG';
export const CLOSE_DIALOG = 'CLOSE_DIALOG';
export const SET_STATUS_MESSAGE = 'SET_STATUS_MESSAGE';
export const CLEAR_STATUS_MESSAGE = 'CLEAR_STATUS_MESSAGE';
export const SET_UTC = 'SET_UTC';
export const UPDATE_WINDOW_SIZE = 'UPDATE_WINDOW_SIZE';
export const UPDATE_SCROLL_INFO = 'UPDATE_SCROLL_INFO';
export const UPDATE_CLIENT_HEIGHT = 'UPDATE_CLIENT_HEIGHT';

// account actions
export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';
export const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS';
export const FETCH_ACCOUNT_SUCCESS = 'FETCH_ACCOUNT_SUCCESS';
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
export const UPDATE_ACCOUNT_SUCCESS = 'UPDATE_ACCOUNT_SUCCESS';
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS';
export const TRANSFER_ACCOUNT = 'TRANSFER_ACCOUNT';
export const SET_CURRENT_ACCOUNT = 'SET_CURRENT_ACCOUNT';
export const CLEAR_CURRENT_ACCOUNT = 'CLEAR_CURRENT_ACCOUNT';
export const UPDATE_ACCOUNT_CLOUD_ROLE = 'UPDATE_ACCOUNT_CLOUD_ROLE';
export const UPDATE_ACCOUNT_CLOUD_ROLE_SUCCESS = 'UPDATE_ACCOUNT_CLOUD_ROLE_SUCCESS';

export const FETCH_ACCOUNT_RESOURCES = 'FETCH_ACCOUNT_RESOURCES';
export const CLEAR_ACCOUNT_RESOURCES = 'CLEAR_ACCOUNT_RESOURCES';

export const FETCH_ACCOUNT_SEARCH_PAGE = 'FETCH_ACCOUNT_SEARCH_PAGE';
export const CLEAR_ACCOUNT_SEARCH = 'CLEAR_ACCOUNT_SEARCH';

export const FETCH_ACCOUNT_SEARCH_PAGE_SUCCESS = 'FETCH_ACCOUNT_SEARCH_PAGE_SUCCESS';
export const FETCH_ACCOUNT_SEARCH_PAGE_FAILURE = 'FETCH_ACCOUNT_SEARCH_PAGE_FAILURE';

export const UPDATE_ACCOUNT_LICENSE = 'UPDATE_ACCOUNT_LICENSE';
export const UPDATE_ACCOUNT_LICENSE_SUCCESS = 'UPDATE_ACCOUNT_LICENSE_SUCCESS';

export const FETCH_ACCOUNT_SUBSCRIPTION = 'FETCH_ACCOUNT_SUBSCRIPTION';
export const FETCH_ACCOUNT_SUBSCRIPTION_SUCCESS = 'FETCH_ACCOUNT_SUBSCRIPTION_SUCCESS';
export const FETCH_ACCOUNT_SUBSCRIPTION_FAILURE = 'FETCH_ACCOUNT_SUBSCRIPTION_FAILURE';
export const DELETE_ACCOUNT_SUBSCRIPTION = 'DELETE_ACCOUNT_SUBSCRIPTION';
export const DELETE_ACCOUNT_SUBSCRIPTION_SUCCESS = 'DELETE_ACCOUNT_SUBSCRIPTION_SUCCESS';
export const FETCH_ACCOUNT_SUBSCRIPTION_ENTITLEMENT_STATUS = 'FETCH_ACCOUNT_SUBSCRIPTION_ENTITLEMENT_STATUS';
export const FETCH_ACCOUNT_SUBSCRIPTION_ENTITLEMENT_STATUS_SUCCESS = 'FETCH_ACCOUNT_SUBSCRIPTION_ENTITLEMENT_STATUS_SUCCESS';

export const FETCH_ACCOUNT_LICENSE_ENTITLEMENTS = 'FETCH_ACCOUNT_LICENSE_ENTITLEMENTS';
export const FETCH_ACCOUNT_LICENSE_ENTITLEMENT = 'FETCH_ACCOUNT_LICENSE_ENTITLEMENT';
export const FETCH_ACCOUNT_LICENSE_ENTITLEMENT_SUCCESS = 'FETCH_ACCOUNT_LICENSE_ENTITLEMENT_SUCCESS';
export const DELETE_ACCOUNT_LICENSE_ENTITLEMENT = 'DELETE_ACCOUNT_LICENSE_ENTITLEMENT';
export const DELETE_ACCOUNT_LICENSE_ENTITLEMENT_SUCCESS = 'DELETE_ACCOUNT_LICENSE_ENTITLEMENT_SUCCESS';
export const UPDATE_ACCOUNT_LICENSE_ENTITLEMENT = 'UPDATE_ACCOUNT_LICENSE_ENTITLEMENT';
export const UPDATE_ACCOUNT_LICENSE_ENTITLEMENT_SUCCESS = 'UPDATE_ACCOUNT_LICENSE_ENTITLEMENT_SUCCESS';
export const CREATE_ACCOUNT_LICENSE_ENTITLEMENT = 'CREATE_ACCOUNT_LICENSE_ENTITLEMENT';
export const CREATE_ACCOUNT_LICENSE_ENTITLEMENT_SUCCESS = 'CREATE_ACCOUNT_LICENSE_ENTITLEMENT_SUCCESS';

export const CREATE_ACCOUNT_PROPERTY = 'CREATE_ACCOUNT_PROPERTY';
export const FETCH_ACCOUNT_PROPERTIES = 'FETCH_ACCOUNT_PROPERTIES';
export const FETCH_ACCOUNT_PROPERTIES_SUCCESS = 'FETCH_ACCOUNT_PROPERTIES_SUCCESS';
export const DELETE_ACCOUNT_PROPERTY = 'DELETE_ACCOUNT_PROPERTIES';
export const DELETE_ACCOUNT_PROPERTY_SUCCESS = 'DELETE_ACCOUNT_PROPERTIES_SUCCESS';

export const CREATE_ACCOUNT_USAGE_CREDITS = 'CREATE_ACCOUNT_USAGE_CREDITS';
export const CREATE_ACCOUNT_USAGE_CREDITS_SUCCESS = 'CREATE_ACCOUNT_USAGE_CREDITS_SUCCESS';
export const DELETE_ACCOUNT_USAGE_CREDITS = 'DELETE_ACCOUNT_USAGE_CREDITS';
export const DELETE_ACCOUNT_USAGE_CREDITS_SUCCESS = 'DELETE_ACCOUNT_USAGE_CREDITS_SUCCESS';
export const FETCH_ACCOUNT_USAGE_CREDITS = 'FETCH_ACCOUNT_USAGE_CREDITS';
export const FETCH_ACCOUNT_USAGE_CREDITS_SUCCESS = 'FETCH_ACCOUNT_USAGE_CREDITS_SUCCESS';
export const FETCH_ACCOUNT_USAGE_CREDITS_FAILURE = 'FETCH_ACCOUNT_USAGE_CREDITS_FAILURE';
export const UPDATE_ACCOUNT_USAGE_CREDITS = 'UPDATE_ACCOUNT_USAGE_CREDITS';
export const UPDATE_ACCOUNT_USAGE_CREDITS_SUCCESS = 'UPDATE_ACCOUNT_USAGE_CREDITS_SUCCESS';

export const FETCH_ACCOUNT_APPLICATIONS = 'FETCH_ACCOUNT_APPLICATIONS';
export const FETCH_ACCOUNT_APPLICATIONS_SUCCESS = 'FETCH_ACCOUNT_APPLICATIONS_SUCCESS';
export const FETCH_ACCOUNT_APPLICATIONS_FAILURE = 'FETCH_ACCOUNT_APPLICATIONS_FAILURE';
export const CLEAR_ACCOUNT_APPLICATIONS = 'CLEAR_ACCOUNT_APPLICATIONS';

export const FETCH_ACCOUNT_MEMBERS_PAGE = 'FETCH_ACCOUNT_MEMBERS_PAGE';
export const FETCH_ACCOUNT_MEMBERS_PAGE_SUCCESS = 'FETCH_ACCOUNT_MEMBERS_PAGE_SUCCESS';
export const FETCH_ACCOUNT_MEMBERS_PAGE_FAILURE = 'FETCH_ACCOUNT_MEMBERS_PAGE_FAILURE';
export const CLEAR_ACCOUNT_MEMBERS = 'CLEAR_ACCOUNT_MEMBERS';

export const FETCH_ACCOUNT_SPACES = 'FETCH_ACCOUNT_SPACES';
export const FETCH_ACCOUNT_SPACES_SUCCESS = 'FETCH_ACCOUNT_SPACES_SUCCESS';
export const FETCH_ACCOUNT_SPACES_FAILURE = 'FETCH_ACCOUNT_SPACES_FAILURE';
export const CLEAR_CURRENT_ACCOUNT_SPACES = 'CLEAR_CURRENT_ACCOUNT_SPACES';

export const FETCH_ACCOUNT_CONTENT = 'FETCH_ACCOUNT_CONTENT';
export const FETCH_ACCOUNT_CONTENT_SUCCESS = 'FETCH_ACCOUNT_CONTENT_SUCCESS';
export const FETCH_ACCOUNT_CONTENT_FAILURE = 'FETCH_ACCOUNT_CONTENT_FAILURE';

// user actions
export const CLEAR_USER_SEARCH = 'CLEAR_USER_SEARCH';
export const FETCH_USER_SEARCH_PAGE = 'FETCH_USER_SEARCH_PAGE';
export const FETCH_USER_SEARCH_PAGE_SUCCESS = 'FETCH_USER_SEARCH_PAGE_SUCCESS';
export const FETCH_USER_SEARCH_PAGE_FAILURE = 'FETCH_USER_SEARCH_PAGE_FAILURE';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const CLEAR_CURRENT_USER = 'CLEAR_CURRENT_USER';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';

export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';

export const FETCH_CURRENT_USER_OWNED_ACCOUNTS = 'FETCH_CURRENT_USER_OWNED_ACCOUNTS';
export const FETCH_CURRENT_USER_OWNED_ACCOUNTS_SUCCESS = 'FETCH_CURRENT_USER_OWNED_ACCOUNTS_SUCCESS';

// space actions
export const SET_SPACE_SEARCH = 'SET_SPACE_SEARCH';
export const CLEAR_SPACE_SEARCH = 'CLEAR_SPACE_SEARCH';
export const FETCH_SPACE_SEARCH_SUCCESS = 'FETCH_SPACE_SEARCH_SUCCESS';
export const FETCH_SPACE_SEARCH_FAILURE = 'FETCH_SPACE_SEARCH_FAILURE';
export const SET_CURRENT_SPACE = 'SET_CURRENT_SPACE';
export const CLEAR_CURRENT_SPACE = 'CLEAR_CURRENT_SPACE';
export const FETCH_SPACE_SUCCESS = 'FETCH_SPACE_SUCCESS';
export const DELETE_SPACE = 'DELETE_SPACE';
export const DELETE_SPACE_SUCCESS = 'DELETE_SPACE_SUCCESS';
export const DELETE_SPACE_FAILURE = 'DELETE_SPACE_FAILURE';

export const FETCH_SPACE_CONNECTIONS_SUCCESS = 'FETCH_SPACE_CONNECTIONS_SUCCESS';
export const CLEAR_SPACE_CONNECTIONS = 'CLEAR_SPACE_CONNECTIONS';

export const FETCH_SPACE_MEMBERS_PAGE = 'FETCH_SPACE_MEMBERS_PAGE';
export const FETCH_SPACE_MEMBERS_PAGE_SUCCESS = 'FETCH_SPACE_MEMBERS_PAGE_SUCCESS';
export const FETCH_SPACE_MEMBERS_PAGE_FAILURE = 'FETCH_SPACE_MEMBERS_PAGE_FAILURE';
export const CLEAR_SPACE_MEMBERS = 'CLEAR_SPACE_MEMBERS';

export const FETCH_SPACE_CONTENT_PAGE = 'FETCH_SPACE_CONTENT_PAGE';
export const FETCH_SPACE_CONTENT_PAGE_SUCCESS = 'FETCH_SPACE_CONTENT_PAGE_SUCCESS';
export const FETCH_SPACE_CONTENT_PAGE_FAILURE = 'FETCH_SPACE_CONTENT_PAGE_FAILURE';
export const CLEAR_SPACE_CONTENT = 'CLEAR_SPACE_CONTENT';

export const UPDATE_SPACE_ROLE = 'UPDATE_SPACE_ROLE';
export const UPDATE_SPACE_ROLE_SUCCESS = 'UPDATE_SPACE_ROLE_SUCCESS';

// content actions
export const SET_CONTENT_SEARCH = 'SET_CONTENT_SEARCH';
export const CLEAR_CONTENT_SEARCH = 'CLEAR_CONTENT_SEARCH';
export const FETCH_CONTENT_SEARCH_SUCCESS = 'FETCH_CONTENT_SEARCH_SUCCESS';
export const FETCH_CONTENT_SEARCH_FAILURE = 'FETCH_CONTENT_SEARCH_FAILURE';

export const FETCH_CONTENT = 'FETCH_CONTENT';
export const FETCH_CONTENT_SUCCESS = 'FETCH_CONTENT_SUCCESS';
export const DELETE_CONTENT_SUCCESS = 'DELETE_CONTENT_SUCCESS';

export const UPDATE_CONTENT_SUCCESS = 'UPDATE_CONTENT_SUCCESS';
export const FETCH_CONTENT_BY_AUTHOR = 'FETCH_CONTENT_BY_AUTHOR';
export const FETCH_CONTENT_BY_AUTHOR_SUCCESS = 'FETCH_CONTENT_BY_AUTHOR_SUCCESS';
export const CLEAR_CURRENT_USER_AUTHORED_CONTENT = 'CLEAR_CURRENT_USER_AUTHORED_CONTENT';

// project actions
export const FETCH_PROJECT_CHILDREN = 'FETCH_PROJECT_CHILDREN';
export const FETCH_PROJECT_CHILDREN_SUCCESS = 'FETCH_PROJECT_CHILDREN_SUCCESS';
export const CLEAR_PROJECT_CHILDREN = 'CLEAR_PROJECT_CHILDREN';

export const FETCH_PROJECT_OUTPUTS_SUCCESS = 'FETCH_PROJECT_OUTPUTS_SUCCESS';
export const CLEAR_PROJECT_OUTPUTS = 'CLEAR_PROJECT_OUTPUTS';

export const FETCH_PROJECT_SESSIONS = 'FETCH_PROJECT_SESSIONS';
export const FETCH_PROJECT_SESSIONS_SUCCESS = 'FETCH_PROJECT_SESSIONS_SUCCESS';
export const CLEAR_PROJECT_SESSIONS = 'CLEAR_PROJECT_SESSIONS';

// application actions
export const SET_APPLICATION_SEARCH = 'SET_APPLICATION_SEARCH';
export const FETCH_APPLICATION_SEARCH_SUCCESS = 'FETCH_APPLICATION_SEARCH_SUCCESS';
export const FETCH_APPLICATION_SEARCH_FAILURE = 'FETCH_APPLICATION_SEARCH_FAILURE';
export const CLEAR_APPLICATION_SEARCH = 'CLEAR_APPLICATION_SEARCH';

export const SET_CURRENT_APPLICATION = 'SET_CURRENT_APPLICATION';
export const CLEAR_CURRENT_APPLICATION = 'CLEAR_CURRENT_APPLICATION';

export const FETCH_APPLICATION = 'FETCH_APPLICATION';
export const FETCH_APPLICATION_SUCCESS = 'FETCH_APPLICATION_SUCCESS';

export const FETCH_APPLICATION_AUTHORIZATIONS = 'FETCH_APPLICATION_AUTHORIZATIONS';
export const FETCH_APPLICATION_AUTHORIZATIONS_SUCCESS = 'FETCH_APPLICATION_AUTHORIZATIONS_SUCCESS';

export const FETCH_APPLICATION_LOGS = 'FETCH_APPLICATION_LOGS';
export const FETCH_APPLICATION_LOGS_SUCCESS = 'FETCH_APPLICATION_LOGS_SUCCESS';

export const RESUME_APPLICATION = 'RESUME_APPLICATION';
export const RESUME_APPLICATION_SUCCESS = 'RESUME_APPLICATION_SUCCESS';
export const SUSPEND_APPLICATION = 'SUSPEND_APPLICATION';
export const SUSPEND_APPLICATION_SUCCESS = 'SUSPEND_APPLICATION_SUCCESS';
export const RESTART_APPLICATION = 'RESTART_APPLICATION';
export const RESTART_APPLICATION_SUCCESS = 'RESTART_APPLICATION_SUCCESS';
export const PURGE_APPLICATION = 'PURGE_APPLICATION';
export const PURGE_APPLICATION_SUCCESS = 'PURGE_APPLICATION_SUCCESS';
export const PURGE_APPLICATION_FAILURE = 'PURGE_APPLICATION_FAILURE';

// task actions
export const SET_CURRENT_TASK = 'SET_CURRENT_TASK';
export const CLEAR_CURRENT_TASK = 'CLEAR_CURRENT_TASK';
