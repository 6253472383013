import { call, put, putResolve, select, spawn } from 'redux-saga/effects';

import * as applicationActions from '~actions/application-actions';
import * as contentActions from '~actions/content-actions';
import * as uiActions from '~actions/ui-actions';

import * as api from '~api';
import * as contentAPI from '~api/content';

import * as statusTypes from '~components/status/status-types';

import * as applicationType from '~constants/application-type';

import * as taskOperations from '~operations/task-operations';

import * as applicationSelectors from '~selectors/application-selectors';

export function* searchApplications(action) {
  try {
    const { data } = yield call(api.searchApplications, action.query);
    yield put(applicationActions.fetchApplicationSearchSuccess(data.applications));
  } catch (err) {
    const message = yield call(api.getErrorMessage, err.response);
    yield put(uiActions.setStatusMessage({ type: statusTypes.ERROR_MESSAGE, message }));
    yield put(applicationActions.fetchApplicationSearchFailure());
  }
}

export function* fetchApplication(action) {
  try {
    const { data } = yield call(api.getApplication, action.applicationId);
    yield put(applicationActions.fetchApplicationSuccess(data));
    // if this is an ide or jupyter application check if this application is the source for content
    if (applicationType.isContentSource(data)) {
      // is there a content already in the store who's source_id matches the application_id?
      const content = yield select(applicationSelectors.getCurrentApplicationContent);
      // if we don't have the content then fetch it
      if (!content) {
        yield spawn(fetchApplicationContentBySourceId, action.applicationId);
      }
    }
  } catch (err) {
    const message = err.response ? api.getErrorMessage(err.response) : err.toString();
    yield put(uiActions.setStatusMessage({ type: statusTypes.ERROR_MESSAGE, message }));
  }
}

export function* fetchApplicationContentBySourceId(action) {
  try {
    const result = yield call(contentAPI.getContentBySourceId, action);
    if (result.data.total > 0) {
      const content = result.data.content[0];
      yield put(contentActions.fetchContentSuccess(content));
    }
  } catch (err) {
    const message = err.response ? api.getErrorMessage(err.response) : err.toString();
    yield put(uiActions.setStatusMessage({ type: statusTypes.ERROR_MESSAGE, message }));
  }
}

export function* fetchApplicationAuthorizations(action) {
  try {
    const { data } = yield call(api.getApplicationAuthorizations, action.applicationId);
    yield put(applicationActions.fetchApplicationAuthorizationsSuccess(action.applicationId, data.authorization, data.total));
  } catch (err) {
    const message = yield call(api.getErrorMessage, err.response);
    yield put(uiActions.setStatusMessage({ type: statusTypes.ERROR_MESSAGE, message }));
  }
}

export function* fetchApplicationLogs(action) {
  try {
    const { data } = yield call(api.getApplicationLogs, action.applicationId);
    yield put(applicationActions.fetchApplicationLogsSuccess(action.applicationId, data));
  } catch (err) {
    const message = err.response ? api.getErrorMessage(err.response) : err.toString();
    yield put(uiActions.setStatusMessage({ type: statusTypes.ERROR_MESSAGE, message }));
  }
}

export function* resumeApplication(action) {
  try {
    let task_id;
    try {
      // resume application
      const { data } = yield call(api.resumeApplication, action.applicationId);
      task_id = data.task_id;
    } catch (err) {
      if (err.response && err.response.status === 409 && err.response.data && err.response.data.task_id) {

        // the failure was because a task is already running for this application,
        // and we need to wait for the task to finish (nothing to see here)
        // TODO: what if the task in progress is terminating the application?
        task_id = err.response.data.task_id;
      } else {
        // this is not an expected error, so bubble it up:
        throw err;
      }
    }

    // wait for the task to finish
    yield putResolve(taskOperations.checkUntilDone(task_id));

    // fetch application
    const { data } = yield call(api.getApplication, action.applicationId);
    yield put(applicationActions.resumeApplicationSuccess(data));

  } catch (err) {
    const message = err.response ? api.getErrorMessage(err.response) : err;
    yield put(uiActions.setStatusMessage({ type: statusTypes.ERROR_MESSAGE, message }));
  }
}

export function* suspendApplication(action) {
  try {
    let task_id;
    try {
      // suspend application
      const { data } = yield call(api.suspendApplication, action.applicationId);
      task_id = data.task_id;
    } catch (err) {
      if (err.response && err.response.status === 409 && err.response.data && err.response.data.task_id) {
        // the failure was because a task is already running for this application,
        // and we need to wait for the task to finish (nothing to see here)
        // TODO: what if the task in progress is terminating the application?
        task_id = err.response.data.task_id;
      } else {
        // this is not an expected error, so bubble it up:
        throw err;
      }
    }

    // wait for the task to finish
    yield putResolve(taskOperations.checkUntilDone(task_id));

    // fetch application
    const { data } = yield call(api.getApplication, action.applicationId);
    yield put(applicationActions.suspendApplicationSuccess(data));

  } catch (err) {
    const message = err.response ? api.getErrorMessage(err.response) : err;
    yield put(uiActions.setStatusMessage({ type: statusTypes.ERROR_MESSAGE, message }));
  }
}

export function* restartApplication(action) {
  try {
    let task_id;
    try {
      // restart application
      const { data } = yield call(api.restartApplication, action.applicationId);
      task_id = data.task_id;
    } catch (err) {
      if (err.response && err.response.status === 409 && err.response.data && err.response.data.task_id) {
        // the failure was because a task is already running for this application,
        // and we need to wait for the task to finish (nothing to see here)
        // TODO: what if the task in progress is terminating the application?
        task_id = err.response.data.task_id;
      } else {
        // this is not an expected error, so bubble it up:
        throw err;
      }
    }

    // wait for the task to finish
    yield putResolve(taskOperations.checkUntilDone(task_id));

    // fetch application
    const { data } = yield call(api.getApplication, action.applicationId);
    yield put(applicationActions.restartApplicationSuccess(data));

  } catch (err) {
    const message = err.response ? api.getErrorMessage(err.response) : err;
    yield put(uiActions.setStatusMessage({ type: statusTypes.ERROR_MESSAGE, message }));
  }
}

export function* purgeApplication(action) {
  const { applicationId } = action;
  try {
    let task_id;
    try {
      // restart application
      const { data } = yield call(api.purgeApplication, applicationId);
      task_id = data.task_id;
    } catch (err) {
      if (err.response && err.response.status === 409 && err.response.data && err.response.data.task_id) {
        // the failure was because a task is already running for this application,
        // and we need to wait for the task to finish (nothing to see here)
        // TODO: what if the task in progress is terminating the application?
        task_id = err.response.data.task_id;
      } else {
        // this is not an expected error, so bubble it up:
        throw err;
      }
    }

    // wait for the task to finish
    yield putResolve(taskOperations.checkUntilDone(task_id));

    yield put(applicationActions.purgeApplicationSuccess(applicationId));
  } catch (err) {
    const message = err.response ? api.getErrorMessage(err.response) : err;
    yield put(uiActions.setStatusMessage({ type: statusTypes.ERROR_MESSAGE, message }));
    yield put(applicationActions.purgeApplicationFailure(applicationId));
  }
}

