import * as contentActions from '~actions/content-actions';
import * as projectActions from '~actions/project-actions';
import * as uiActions from '~actions/ui-actions';

import * as projectsAPI from '~api/projects';

import * as contentState from '~constants/content-state';

/**
 * Thunk action creator to mark a project as deleted
 *
 * @param   {number}   projectId ID of the project to mark as deleted
 * @returns {function}           Thunk to mark a project as deleted
 */
export const deleteProject = (projectId) => async (dispatch) => {
  const { data: project } = await projectsAPI.updateProject(projectId, { state: contentState.DELETED });
  dispatch(contentActions.updateContentSuccess(project));
};

/**
 * Thunk action creator to fetch the outputs for a given project
 *
 * @param   {number}   projectId ID of the project for which to fetch outputs
 * @param   {object}   options   Sort options for the list
 * @returns {function}           Thunk to fetch outputs for a specified project
 */
export const fetchProjectOutputs = (projectId, sort) => async (dispatch) => {
  try {
    const { data } = await projectsAPI.getProjectOutputs(projectId, sort);
    dispatch(projectActions.fetchProjectOutputsSuccess(projectId, data.outputs, data.total));
  } catch (error) {
    dispatch(uiActions.setErrorMessage(error));
  }
};
